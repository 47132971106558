import {useUserStore} from '@/stores/user';
// import {useAuth} from "@/composables/useAuth";

export default defineNuxtRouteMiddleware( (to, from) => {

    /*function useCurrentDomain() {
        if (process.server) {
            const headers = useRequestHeaders(['host'])
            return headers.host
        } else {
            return window.location.hostname
        }
    }

    if (process.server) {
        const userStore = useUserStore();
        const token = userStore.getToken;
        const tokenFromUseCookie = useCookie('token')
        console.log('server middleware tokenFromUseCookie = ', tokenFromUseCookie.value);
        console.log('server middleware userStore token = ', token);
        console.log(useCurrentDomain());
    }
    if (process.client) {

        const tokenFromUseCookie = useCookie('token')
        console.log('client middleware tokenFromUseCookie = ', tokenFromUseCookie.value);
        console.log(useCurrentDomain());
    }*/

    if (process.server) {
        return

    } else {
        const userStore = useUserStore();
        const token = userStore.getToken;
        if (!token) {
            return navigateTo({
                path: '/',
                query: {
                    ...to.query,
                    redirect: to.fullPath
                },
            });
        }
    }

});
